.Upgrade {
    display: flex;
    flex-direction: column;
}

.upgrade-country {
    display: flex;
    background-color: #00213D;
    border-radius: 5px;
    width: 40%;
    justify-content: space-between;
    /* margin-bottom: 30px; */
}

.upgrade-country label {
    display: flex;
    font-size: 15px;
    padding: 7px;
    color: white;
    width: 30%;
    justify-content: center;
}

.upgrade-country select {
    font-size: 15px;
    padding: 7px;
    outline: none;
    width: 70%;
}

.upgrade-country input {
    font-size: 13px;
    padding: 7px;
    outline: none;
    width: 70%;
    border: 1px solid darkgrey;
}

.upgrade-pay {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 7px;
    font-size: 25px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.upgrade-pay h1 {
    font-size: 15px;
}

.upgrade-pay h3 {
    color: green;
}

#btnGuide {
    margin-left: 10px;
    background-color: #00213D;
    color: white;
    border: 0px;
    border-radius: 5px;
}

#btnGuide:hover {
    background-color: #2f77b7;
    cursor: pointer;
}

#loading {
    width: 37px;
    height: 37px;
    margin-left: 10px;
}

#suscribeBtn {
    width: 40%;
    margin-bottom: 10px;
}