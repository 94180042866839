.logo {
  /* height: 32px; */
  height: 40px;
  background: #00213D;
  margin: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

html {
  width: 100%;
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  margin: 0;
  font-size: 10px;
}
#root {
  width: 100%;
  height: 100%;
}
.App {
  height: 100%;
}

img {
  max-width: 100%;
  max-height: 100%;
}

@media screen and (min-width: 500px) {
  .modal-card {
    width: 100%;
    left: 0;
  }
}

/* .container {
  width: 100%;
  flex: 1 0 60%;
  display: flex; flex-direction: column;
} */

/**************************
* General
*/

/* .affiliate {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 15px;
  padding: 0px 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #00213D;;
  color: #00213D;
}

.profileImage {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  background-color: whitesmoke;
} */

/* button{
  padding: 1% 10% 1% 10%;
  border-radius: 5px;
  font-weight: 510;
  border-style:solid;
  background-color: #F2AA12;
  color: #00213D;
  border-color: #F2AA12;
  border-width: 1px;
} */

/* button:disabled{
  padding: 1% 10% 1% 10%;
  border-radius: 5px;
  border-style:solid;
  background-color: gray;
  cursor: pointer;
  color: #00213D;
  border-color: #00213D;
  border-width: 1px;
} */
/* .guideButton {
  padding: 5px;
}
.guideButton:hover {
  padding: 5px;
} */

/* button:hover{
  padding: 1% 10% 1% 10%;
  border-radius: 5px;
  border-style:solid;
  background-color: gray;
  cursor: pointer;
  color: #00213D;
  border-color: #00213D;
  border-width: 1px;
} */

/* form { */
  /* This bit sets up the horizontal layout */
  /* margin: 5px;
  display:flex;
  flex-direction:row;
  align-items: center;
  font-size: small; */
  /* This bit draws the box around it */
  /* border-radius: 15px;
  background-color: #EDEDED;
  border:1px solid grey;
  width: 325px;
  padding:7px;
  justify-content: space-evenly; */
/* } */


/* input{ */
  /* background-color: #EDEDED; */
  /* padding: 0px 0px 0px 10px;
  flex-grow:1;
  background-color: #EDEDED;
  border: none;
  border-radius: 15px; */
  /* border-style:solid; */
/* } */

/* form div {
  color: #00213D;
  padding: 0px 10px 0px 0px;
}

form div:hover {
  cursor: pointer;
  font-weight: bold;
} */
/* 
.property {
  width: 100%;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.log p{
  display: flex;
  margin: 0;
  border-radius: 15px;
  padding: 0px 10px;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: #00213D;;
  color: #00213D;
}
.log{
  margin: 15px;
} */

/**************************
* Dashboard
*/

/* .dashboard {
  position: relative;
  background-image: linear-gradient(135deg, #183440, #041424, #03111C);
  flex-grow: 1;
  color: #f1f1f1;
  padding-bottom: 4em;
}
.dashboard > div {
  height: 100%;
} */

/* .dashboard .home {
  height: 100%;
  color: white;
  text-align: left;
  padding: 50px;
} */

/* .dashboard .nohome {
  padding: 50px;
  color: black;
  font-family: ApexNew;
  src: url("/fonts/ApexNew-Book.otf") format("opentype");
} */

/* .home h1 {
  color: white;
  margin: 0;
  font-size: 3em;
}

.home h2 {
  font-size: 2em;
  font-weight: bold;
  color: #F2AA12;
}
.home p {
  font-size: 1.4em;
  text-align: justify;
  color: white;
} */
/* 
.copy {
  position: absolute;
  left: 50%;
  bottom: .5em;
  width: 100%;
  height: auto !important;
  font-size: 1.4em;
  text-align: center;
  transform: translateX(-50%);
} */

/* .dashboard .nohome .card{ */
  /* color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.4em; */
/* } */

/* .nohome h1{
  color: #F2AA12;
  font-weight: initial;
  font-size: 18px;
  margin-bottom: 30px;
} */

/**************************
* Sidebar
*/

.sidenav {
  width: 100%;
  background-color: #00213D;
  padding: 2em 3.3em;
}
.sid-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* The navigation menu links */
.sidenav .sidebar-control {
  display: block;
  max-width: 12em;
  padding-bottom: 1em;
  border-bottom: 1px solid #0c3f60;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 1em;
  font-size: 1.6em;
  font-weight: 700;
  color: #f1f1f1;
  transition: all ease-in-out 200ms;
}

.sidenav>img {
  display: block; 
  max-width: 100%;
  margin: 0 auto 1em;
}

.sidebar-control:hover {
  /* color: #f1f1f1; */
  color: #F2AA12;
  cursor: pointer;
}

.affiliates {

  display: flex;
  flex-direction: column;
  align-items: stretch;
  width: 100%;

}
/**************************
* Header
*/

/* .header {
  padding: 2em 5em;
  background-color: #011C2D;
  font-weight: 700;
  display: flex;
  flex-direction: row-reverse;
}

.header p {
  margin: 0;
  font-size: 1.4em;
  color: #f1f1f1;
}
.header p + p {
  margin-top: .5em;
}
.header span {
  color: #5C7685;
}

.profileButton {
  text-align: right;
}

.profileButton:hover {
  cursor: pointer;
}
.menu-btn {
  cursor: pointer;

}
.menu-btn .bar1, .menu-btn .bar2, .menu-btn .bar3 {
  width: 35px;
  height: 2px;
  background-color: #fff;
  margin: 6px 0;
  transition: 0.4s;
}
.menu-btn.changed .bar1 {
  -webkit-transform: rotate(-45deg) translate(-10px, 11px);
  transform: rotate(-45deg) translate(-10px, 11px);
}
.menu-btn.changed .bar2 {opacity: 0;}
.menu-btn.changed .bar3 {
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}
@media screen and (max-width: 767px) {
  .controls-container {
    position: relative;
    display: block;
    overflow: hidden;
    max-height: 0;
    transition: all ease-in-out 300ms;
  }
  .controls-container.expanded {
    max-height: 90vh;
  }
}
@media screen and (min-width: 768px) {
  .App {
    flex-direction: row;
  }
  .sidenav {
    max-width: 22.4em;
  }
  .controls-container {
    margin-top: 8em;
  }
  .menu-btn {
    display: none;
  }
} */