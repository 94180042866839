/* 'word-break: break-all;' fix: https://stackoverflow.com/questions/862010/how-to-wrap-text-of-html-button-with-fixed-width */

.break-all {
  word-break: break-all;
  white-space: normal;
}

.affiliates-balance {
  display: flex;
  flex-direction: row;
  align-items: center;
  /* margin-left: 20px; */
  padding-left: 20px;
  gap: 20px;
  margin-bottom: 30px;
  width: 100%;
  border: 1px solid rgb(240, 240, 240);
  /* border-radius: 5px; */
}

.affiliates-pay {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 25px;
  margin-bottom: 10px;
  margin-top: 10px;
  gap: 10px;
}

.affiliates-pay h3 {
  margin: 0;
  color: green;

}